


























































import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    items: Array,
    fields: Array,
    loading: Boolean
  }
})

export default class ExportGrid extends Vue {
  multipleSelection: any = []
  case_ids: any = []
}
