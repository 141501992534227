




















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins],
  props: {
    searchField: Array
  },
})
export default class Form extends Vue<globalMixins> {
  @Prop ()
  searchField: any[]

  advancedForm: any[] = [{
    include: true,
    field_type: null,
    ge: 0,
    le: 0,
    options: [],
    field_id: null,
    cascaderoOts: []
  }]
  isContain: object[] = [{
    value: true,
    label: '包含'
  }, {
    value: false,
    label: '不包含'
  }]
  selectType: object[] = [{
    value: 'select',
    label: '单选类'
  }, {
    value: 'multi-select',
    label: '多选类'
  }, {
    value: 'number',
    label: '数值类'
  }]
  currentOption: any[] = []
  typeNameOptions: any[] = []
  searchfieldOptions: any[] = [{
    value: 'select',
    label: '单选类',
    children: []
  }, {
    value: 'multi-select',
    label: '多选类',
    children: []
  }, {
    value: 'number',
    label: '数值类',
    children: []
  }]
  // le 输入内容限制
  leChange (event, ge, index) {
    let le = event.target.value
    if (Number(le) < ge) {
      this.advancedForm[index].le = this.advancedForm[index].ge
      this.warnMsg('不得小于最小值!')
    }
  }
  // 双级联动的二级数据匹配
  matchSubType (type) {
    let arr = []
    this.searchField.length !== 0 && this.searchField.map(item => {
      if (item.type === type) {
        arr.push({
          value: item.name,
          label: item.name
        })
      }
    })
    return arr
  }
  matchSearchField () {
    this.searchfieldOptions.map((item, index) => {
      switch (item.value) {
        case 'number':
          item.children = this.matchSubType('number')
          break;
        case 'select':
          item.children = this.matchSubType('select')
          break;
        case 'multi-select':
          item.children = this.matchSubType('multi-select')
          break;
        }
    })
  }
  cascaderChange (value, index) {
    if (value.length >= 2) {
      // 在选择题目名称时清空options
      this.advancedForm[index].options = []
      // 赋值当前的type
      this.advancedForm[index].field_type = value[0]
      // 根据name找到当前的obj
      let currentObj = this.searchField.find(item => {
        return item.name === value[1]
      })
      // 再根据obj找到题目对应的options
      this.advancedForm[index].allOptions = currentObj.options
      this.advancedForm[index].field_id = currentObj.id
    }
  }
  // 增加一条筛选条件
  addNewCondition () {
    this.advancedForm.push({
      include: true,
      field_type: null,
      cascaderoOts: [],
      ge: 1,
      le: 1,
      options: [],
      field_id: null
    })
  }
  // 删除当前筛选数据
  delBtn (index) {
    this.advancedForm.splice(index, 1)
  }
  onSubmit () {
    this.advancedForm.map((item, index) => {
      // 删除不需要的字段 暂时先不删除，删除的话还要记录allOptions和cascaderoOts
      // delete item['allOptions']
      // delete item['cascaderoOts']
      // delete item['currentOptions' + index]
      // delete item['typeNameOptions' + index]
    })
    this.$emit('doSearch', this.advancedForm)
  }
  resetForm() {
    this.advancedForm = [
      {
        include: true,
        field_type: null,
        cascaderoOts: [],
        ge: 1,
        le: 1,
        options: [],
        field_id: null
      }
    ]
  }
  @Watch('searchField')
  searchFieldChange(val: any[], old: any[]){
    if (val.length !== 0) {
      this.matchSearchField()
    }
  }
}
