




















































import Vue from 'vue'
import Component from 'vue-class-component'
import dashboardHeader from '@/components/dashboard/header.vue'
import dashboardAside from '@/components/dashboard/aside.vue'
import advancedForm from '@/components/export/form.vue'
import searchGrid from '@/components/export/grid.vue'
import utils from "@/utils/util.ts"
import { Getter, Action } from 'vuex-class'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    dashboardHeader,
    dashboardAside,
    advancedForm,
    searchGrid
  },
  mixins: [globalMixins]
})
export default class ATSViewsExportIndex extends Vue<globalMixins> {
  @Action('advancedSearchFields') advancedSearchFields: Function
  @Getter('searchField') searchField: Object[]
  @Action('advancedSearch') advancedSearch: Function
  @Getter('searchList') searchList: Object[]
  @Action('doExport') doExport: Function
  @Getter('exportUrl') exportUrl: String
  
  loading: boolean = false
  flag: boolean = false
  pagination: any = {
    page: 1,
    size: 10
  }
  currentItem: object = {}
  formatType: object[] = [{
    icon: 'icon-yasuobao',
    name: '文件压缩包',
    active: false,
    type: 'zip'
  }, {
    icon: 'icon-excel',
    name: 'excel',
    active: false,
    type: 'excel'
  }, {
    icon: 'icon-CSV',
    name: 'CSV',
    active: false,
    type: 'csv'
  }]
  exportDisabled: boolean = true
  exportDialogVisible: boolean = false
  keyword: string = ''
  query: object = []
  project: any = JSON.parse(localStorage.getItem("projects"))
  created () {
    this.getFields()
  }
  mounted () {
    this.doSearch(this.query)
  }
  doKeywordSearch () {
    this.pagination.page = 1
    this.doSearch(this.query)
  }
  selectFormat (item, i) {
    this.exportDisabled = false
    this.currentItem = item
    this.formatType.map((v, index) => {
      v['active'] = (index === Number(i)) ? true : false
    })
  }
  openDialog () {
    this.exportDialogVisible = true
    this.formatType.map((item: any) => {
      item['active'] = false
    })
  }
  // 导出按钮
  doExportBtn () {
    this.exportDialogVisible = true
    let params = {
      projectId: this.project.id,
      query: this.query,
      keyword: this.keyword,
      type: (this.currentItem as any).type
    }
    this.doExport( { params } )
    .then(() => {
      console.log('exportUrl: ', typeof this.exportUrl)
      let tempwindow: any = window.open('_blank')
      tempwindow.location = this.exportUrl
      this.successMsg('导出成功！')
    })
    .catch((err) => {
      this.errorMsg('导出失败, 请检查浏览器是否已允许弹出窗!')
    })
  }
  // 匹配units
  matchIsUnits (searchQuery, lists) {
    lists.map((item, index) => {
      let obj = searchQuery.find(v => v.field_id === item.id)
      if (obj) {
        obj.is_selected_num = (item.units && item.units.length) > 0 ? true : false //is_selected_num：判断是否为数值类型
      }
    })
    return searchQuery
  }
  doSearch (query?: any) {
    this.loading  = true
    this.query = this.matchIsUnits(query, this.searchField)
    let pagination = utils.pager(this.pagination.page, this.pagination.size)
    let params = {
      projectId: this.project.id,
      query: query,
      limit: pagination.limit,
      offset: pagination.offset,
      keyword: this.keyword
    }
    this.advancedSearch({ params })
    .then(() => {
      setTimeout(() => {
        this.loading = false
      }, 300)
    })
  }
  onPagerSizeChange (val) {
    this.pagination.size = val
    this.doSearch(this.query)
  }
  onPagerCurrentChange (val) {
    this.pagination.page = val
    this.doSearch(this.query)
  }
  getFields () {
    let params = {
      projectId: this.project.id
    }
    this.advancedSearchFields({ params })
  }
  doAdvancedSearch () {
    this.flag = !this.flag
  }
}
