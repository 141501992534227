class Utils {
    /**
     * 判断是否为字符串类型
     * @param  {} str 字符串
     */
    isString(str: string) {
      return Object.prototype.toString.call(str) === '[object String]'
    }

    /**
     * 判断是否为对象类型
     * @param  {} obj 对象
     */
    isObject(obj: object) {
        return Object.prototype.toString.call(obj) === '[object Object]'
    }

    /**
     * 判断Email格式
     * @param email 字符串
     */
    isEmail(email: string) {
        return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(email)
    }

    /**
     * 验证是否是有效IP地址
     * @param ip
     */
    isIP(ip: string) {
        return /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(ip)  
    }

    /**
     * 验证是否是有效手机号
     * @param str
     */
    isPhone(str: string) {
        return /^[1][3,4,5,6,7,8][0-9]{9}$/.test(str)
    }

    /**
     * 分页偏移量
     * @param page
     * @returns {boolean}
     */
    pager (page: any, limit: any) {
        page = isNaN(page) ? 1 : page
        limit = isNaN(limit) ? 10 : limit
        return {offset: (page - 1) * limit, limit}
    }
    /***
     * 
     */
    dateFormat(fmt: string) {
        let date: any = new Date()
        let o: any = {
            // 月份
            'M+': date.getMonth() + 1,
            // 日
            'd+': date.getDate(),
            // 小时
            'h+': date.getHours(),
            // 分
            'm+': date.getMinutes(),
            // 秒
            's+': date.getSeconds(),
            // 季度
            'q+': Math.floor((date.getMonth() + 3) / 3),
            // 毫秒
            'S': date.getMilliseconds()
        }
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        for (var k in o)
        if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        return fmt
    }
    /**
     * 防抖动
     * @param
     * 使用示例 this.$nextTick(()=>{ this.$refs.mouse.onmousemove = utils.debounce(this.count, 1000)})
     */
    debounce (func: any, wait: number, immediate?: boolean) {
        let timeout, args, context, timestamp, result
        let later = function() {
            // 据上一次触发时间间隔
            let last = Date.now() - timestamp
            // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
            if (last < wait && last > 0) {
                timeout = setTimeout(later, wait - last)
            } else {
                timeout = null
                // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
                if (!immediate) {
                    result = func.apply(context, args)
                    if (!timeout) context = args = null
                }
            }
        }
        return function() {
            context = this
            args = arguments
            timestamp = Date.now()
            let callNow = immediate && !timeout
            // 如果延时不存在，重新设定延时
            if (!timeout) timeout = setTimeout(later, wait)
            if (callNow) {
                result = func.apply(this, args)
                context = args = null
            }
            return result
        }
    }
    /**
     * 节流
     * @param
     */
    throttle (func: any, wait: number, options?: any) {
        let context, args, result
        let timeout = null
        // 上次执行时间点
        let previous = 0
        if (!options) options = {}
        // 延迟执行函数
        let later = () => {
            // 若设定了开始边界不执行选项，上次执行时间始终为0
            previous = options.leading === false ? 0 : Date.now()
            timeout = null
            result = func.apply(context, args)
            if (!timeout) context = args = null
        }
        return function() {
            let now = Date.now()
            // 首次执行时，如果设定了开始边界不执行选项，将上次执行时间设定为当前时间。
            if (!previous && options.leading === false) previous = now
            // 延迟执行时间间隔
            let remaining = wait - (now - previous)
            context = this
            args = arguments
            // 延迟时间间隔remaining小于等于0，表示上次执行至此所间隔时间已经超过一个时间窗口
            // remaining大于时间窗口wait，表示客户端系统时间被调整过
            if (remaining <= 0 || remaining > wait) {
                clearTimeout(timeout)
                timeout = null
                previous = now
                result = func.apply(context, args)
                if (!timeout) context = args = null
                //如果延迟执行不存在，且没有设定结尾边界不执行选项
                } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining)
                }
                return result
            }
      }
}

export default new Utils()
